import dayjs from 'dayjs';
import { FABRICATION_ORDERS_QUERY } from '~/graphql/fabrication_orders';
import { useConsumption } from './useConsumption';
import type { FabricationOrder } from '~/types/fabricationOrder';
import { provideApolloClient } from '@vue/apollo-composable';

export const useWaterEPI = () => {
  const { getConsumption } = useConsumption();
  const { apolloClient } = useApollo();
  provideApolloClient(apolloClient);

  const getConsumptionByOFID = async (options: { meterId: number; ofId: number }) => {
    // Get the fabrication order data
    const result: { data: { fabrication_orders_by_pk: FabricationOrder } } = await apolloClient.query({
      query: FABRICATION_ORDERS_QUERY,
      variables: { id: options.ofId },
    });

    // Get the comsuption by the OF
    const consumptionData = await getConsumption({
      meterId: options.meterId,
      start: dayjs.utc(result.data.fabrication_orders_by_pk.start).tz('Europe/Paris').format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs.utc(result.data.fabrication_orders_by_pk.end).tz('Europe/Paris').format('YYYY-MM-DDTHH:mm:ss'),
      aggregationLevel: 'minute',
    });

    return {
      consumption: consumptionData,
      fabricationOrder: result.data.fabrication_orders_by_pk,
    };
  };

  return {
    getConsumptionByOFID,
  };
};
