<script setup lang="ts">
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useFilter } from '../../composables/useFilter';
import { useConsumption } from '../../composables/useConsumption';
import { useI18n } from 'vue-i18n';

// Components
import ConsumptionGraph from '~/app-modules/explore-water/components/graphs/ConsumptionGraph.vue';
import ConsumptionZoomModal from '~/app-modules/explore-water/components/modal/ConsumptionZoomModal.vue';

dayjs.extend(weekOfYear);

// Composables
const { t } = useI18n();
const router = useRouter();
const { getAggregateFilterOption, getAggregationMeter } = useFilter();
const { getAlertTypeFromAggregationLevel } = useConsumption();
const { proxy } = useScriptSegment();

// Props
defineEmits<{
  (e: 'update-date-range', value: { start: string; end: string }): void;
}>();
const props = defineProps<{
  dateRange: { start: string; end: string };
}>();

const { datasets, timestamps, data, loading, aggregationLevel, groupChild, childMeters, reload } = useExploreWater({
  dateRange: props.dateRange,
});

// Data
const modal = ref(false);
const zoomModal = ref(false);
const zoomPeriod = ref<{ start: string; end: string } | null>(null);

// Load data
const toast = inject('toast') as any;

// Methods
const onLabelClick = (event: any) => {
  if (groupChild.value === 'child_meter') {
    appStore().setMeter(event.id);
    router.push(`/explore/${event.id}`);
    return;
  }

  const meterId = appStore().getMeter;
  if (!meterId) return;
  const start = dayjs(event.consumption.date);
  const end = dayjs(event.consumption.date);
  const meter = meterStore().getMeter(meterId);

  if (meter && meter.meter_type.name === 'grdf') {
    toast.message = t('global.can_not_zoom_on_grdf_meter');
    toast.success = true;
    return;
  }

  if (aggregationLevel.value === 'day') {
    zoomPeriod.value = { start: start.startOf('day').format('YYYY-MM-DD'), end: end.endOf('day').format('YYYY-MM-DD') };
  } else if (aggregationLevel.value === 'week') {
    zoomPeriod.value = {
      start: start.startOf('day').format('YYYY-MM-DD'),
      end: end.add(6, 'days').endOf('day').format('YYYY-MM-DD'),
    };
  } else if (aggregationLevel.value === 'month') {
    zoomPeriod.value = {
      start: start.startOf('day').startOf('month').format('YYYY-MM-DD'),
      end: end.endOf('month').endOf('day').format('YYYY-MM-DD'),
    };
  }
  zoomModal.value = true;
};

// Computed
const totalConsumption = computed(() => {
  return data.value
    .map((d: any) => {
      return d.consumption.map((d: any) => {
        return d.consumption_kwh;
      });
    })
    .flat()
    .reduce((acc: number, curr: number) => acc + curr, 0);
});
const alert = computed(() => {
  const meterId = appStore().getMeter;
  if (!meterId) return null;
  return alertStore().getAlertByMeterIdAndType(meterId, getAlertTypeFromAggregationLevel(aggregationLevel.value));
});
const aggregateOption = computed(() => getAggregateFilterOption());
const showByOption = computed(() => getAggregationMeter());

const title = computed(() => {
  const start = dayjs(props.dateRange.start);
  const end = dayjs(props.dateRange.end);

  return t('analytics.consumption_for_to', {
    start: start.format('DD/MM/YYYY'),
    end: end.format('DD/MM/YYYY'),
  });
});

// Watchers
watch(
  () => props.dateRange,
  () => {
    reload(props.dateRange);
  },
  { deep: true },
);

onMounted(() => {
  proxy.page('Offconsumption');
});
</script>

<template>
  <div class="flex flex-col gap-3">
    <div class="grid gap-4">
      <ui-stat-card
        :loading="loading"
        :title="$t('analytics.total_consumption')"
        icon="Gauge"
        type="water-consumption"
        :data="totalConsumption"
      />
    </div>
    <ui-card :title="title">
      <template #header>
        <ui-dropdown
          v-model="groupChild"
          :placeholder="$t('global.show_by')"
          :items="showByOption"
          :disabled="childMeters?.length === 0"
          @update:model-value="reload({ start: props.dateRange.start, end: props.dateRange.end })"
        />
        <ui-dropdown
          v-model="aggregationLevel"
          :placeholder="$t('global.group_by')"
          :items="aggregateOption"
          @update:model-value="reload({ start: props.dateRange.start, end: props.dateRange.end })"
        />
      </template>
      <!-- Information -->
      <div v-if="aggregationLevel === 'week'">
        <ui-info
          v-if="alert"
          class="mb-4"
          :message="$t('global.alert_set_as', { value: alert?.params.standard_value }) + ' kWh'"
          :link="$t('global.update')"
          @click-link="modal = true"
        />
        <ui-info v-else class="mb-4" :link="$t('global.create')" :message="$t('global.no_alert_set')" @click-link="modal = true" />
      </div>

      <!-- Error -->
      <ui-error
        v-if="!loading && data.length === 0"
        :title="$t('error.title')"
        :message="$t('error.impossible_to_reatrive_consumption_data')"
      />
      <ConsumptionGraph
        :data="data"
        :datasets="datasets"
        :timestamps="timestamps"
        :loading="loading"
        :aggregation-level="aggregationLevel"
        :alert="alert"
        @label-click="onLabelClick"
      />
    </ui-card>
  </div>

  <alert-modal v-model="modal" type="weekly_consumption" :alert-id="alert?.id" />
  <consumption-zoom-modal v-if="zoomModal && zoomPeriod" v-model="zoomModal" :aggregation-level="aggregationLevel" :period="zoomPeriod" />
</template>
