<script setup lang="ts">
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';

// Components
import EpiOFZoomModal from '../modal/EpiOFGraphModal.vue';
import { epiServices } from '../../services/epi';

// Composables
const { t } = useI18n({ inheritLocale: true });
const { formatNumberToIsoEuroNumber, formatNumberToIsoNumber } = useNumbers();
const service = epiServices();
const route = useRoute();
const { getUnitsByMeter } = useUnits();

// Data
const alertModal = ref<boolean>(false);
const ofModal = ref<boolean>(false);
const ofId = ref<number | null>(null);
const ofCost = ref<string | null>(null);

// Methods
const openOFModal = (index: number) => {
  if (!service.graphFormatEPIResponse) return;
  const production = service.fabricationOrdersFiltered[index].quantity;
  const costByQuantity = service.fabricationOrdersFiltered[index].fabrication_order_epis[0].cost_by_quantity;
  ofId.value = service.fabricationOrdersFiltered[index].id;
  ofCost.value =
    formatNumberToIsoEuroNumber(costByQuantity * production) + ` (${costByQuantity.toFixed(2)} € / ${getUnitsByMeter.value.symbol})`;
  ofModal.value = true;
};

// Computed
const alert = computed(() => alertStore().getAlertByMeterIdAndType(parseInt(route.params.id as string), 'EPI'));

const getCo2byKWh = computed(() => useCO2().getCo2byKWhByMeterId(parseInt(route.params.id as string)));

const options = computed(() => {
  return {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: t('analytics.epi_kwh_by', { value: getUnitsByMeter.value.symbol }),
          padding: 12,
        },
        grace: '25%',
      },
      x: {
        ticks: {
          callback: (value: any) => {
            if (!service.loading) {
              return dayjs(service.graphFormatEPIResponse.timestamps[value]).format('DD/MM/YYYY HH:mm');
            }
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return `${context.formattedValue} kWh / ${getUnitsByMeter.value.symbol}`;
          },
          title: (context: any) => {
            return dayjs(service.graphFormatEPIResponse.timestamps[context[0].dataIndex]).format('DD/MM/YYYY HH:mm');
          },
          footer: (context: any) => {
            if (!service.graphFormatEPIResponse) return;

            const fabrication_order = service.fabricationOrdersFiltered[context[0].dataIndex];
            const consumption = fabrication_order.fabrication_order_epis[0].consumption_kwh.toFixed(0);
            const production_kg = fabrication_order.quantity;
            const start = dayjs(fabrication_order.start).format('DD/MM/YYYY HH:mm');
            const end = dayjs(fabrication_order.end).format('DD/MM/YYYY HH:mm');

            const productIdTooltip =
              fabrication_order.fabrication_order_products.length > 0 ? [`ProductId: ${fabrication_order.fabrication_order_products}`] : [];

            const costTooltip = fabrication_order.fabrication_order_epis[0].cost_by_quantity
              ? [
                  `${t('global.cost_per_unit')}: ${fabrication_order.fabrication_order_epis[0].cost_by_quantity.toFixed(
                    2,
                  )} € / ${getUnitsByMeter.value.symbol}`,
                ]
              : [];

            const c02 =
              getCo2byKWh.value >= 1000
                ? [
                    `${t('global.co2_pear_piece')}: ${formatNumberToIsoNumber(
                      consumption * (getCo2byKWh.value / 1000),
                    )} kg / ${getUnitsByMeter.value.symbol}`,
                  ]
                : [
                    `${t('global.co2_pear_piece')}: ${formatNumberToIsoNumber(consumption * getCo2byKWh.value)} g / ${
                      getUnitsByMeter.value.symbol
                    }`,
                  ];

            return [
              `${t('global.Start')}: ${start}`,
              `${t('global.End')}: ${end}`,
              `${t('analytics.consumption')}: ${consumption} kWh`,
              `${t('analytics.productions_kg')}: ${production_kg} ${getUnitsByMeter.value.symbol}`,
            ]
              .concat(productIdTooltip, costTooltip, c02)
              .join('\n');
          },
        },
      },
      annotation: {
        annotations: {
          energyLimitPriceAnnotation: {
            display: alert.value ? alert.value.params.standard_value : 0,
            type: 'line',
            borderColor: 'rgba(150, 0, 0, 0.6)',
            borderWidth: 2,
            scaleID: 'y',
            value: alert.value ? alert.value.params.standard_value : 0,
          },
        },
      },
    },
  };
});
</script>

<template>
  <ui-card :title="$t('analytics.analysis_EPI')">
    <div v-if="service.graphFormatEPIResponse.timestamps.length !== 0 && !service.loading">
      <ui-info
        v-if="alert"
        class="mb-4"
        :message="$t('global.alert_set_as', { value: alert?.params.standard_value }) + ' kWh / ' + getUnitsByMeter?.symbol"
        :link="$t('global.update')"
        @click-link="alertModal = true"
      />
      <ui-info v-else class="mb-4" :link="$t('global.create')" :message="$t('global.no_alert_set')" @click-link="alertModal = true" />
    </div>

    <div class="flex flex-1 flex-col-reverse lg:flex-row gap-2.5 lg:gap-6">
      <div class="flex flex-col w-full gap-2 max-h-xl rounded">
        <template v-if="!service.loading && service.graphFormatEPIResponse.timestamps.length !== 0">
          <ui-chart
            type="bar"
            :data="{
              labels: service.graphFormatEPIResponse.timestamps,
              datasets: [
                {
                  label: $t('global.consumption'),
                  data: service.graphFormatEPIResponse.epis ?? [],
                  backgroundColor: '#4EBB93',
                },
              ],
            }"
            :options="options"
            :plugins="{
              'chartjs-plugin-zoom': true,
              'chartjs-plugin-annotation': true,
              legend: false,
            }"
            @bar-click="openOFModal($event)"
          />
        </template>

        <ui-warning
          v-else-if="service.graphFormatEPIResponse.timestamps.length === 0 && !service.loading"
          :message="$t('IPE.no_fo_selected_period')"
        />

        <div v-else>
          <ui-loader />
        </div>
      </div>
    </div>
  </ui-card>

  <!-- Modal for consumption Graph -->
  <alert-modal v-if="alertModal" v-model="alertModal" type="EPI" :alert-id="alert?.id" />

  <!-- Modal for zoom on a OF -->
  <EpiOFZoomModal v-if="ofModal && ofId" v-model="ofModal" :of-id="ofId" :cost="ofCost" />
</template>
