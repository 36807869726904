<script setup lang="ts">
import { epiServices } from '../../services/epi';

// Components
import AnalyticsIpeGraph from '~/app-modules/explore-water/components/graphs/EpiGraph.vue';
import AnalyticsIpeTable from '~/app-modules/explore-water/components/tables/IpeTable.vue';

// Composables
const service = epiServices();

// Lifecycle
onMounted(async () => {
  await service.fetchFabricationOrders();
  window.analytics.page('analytics', 'IPETab');
});

watch(
  () => [appStore().getExploreDateRange, appStore().getMeter],
  async () => {
    await service.fetchFabricationOrders();
  },
);
</script>
<template>
  <div class="flex flex-col gap-4">
    <ui-card>
      <ui-search-bar v-model="service.search" :placeholder="$t('global.find_product')" />
    </ui-card>
    <analytics-ipe-graph />
    <analytics-ipe-table />
  </div>
</template>
