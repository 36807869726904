export const useConsumption = () => {
  // Composables
  const { getConsumptionByMeter } = useApi();
  const getMeter = (options: { meterId: number }) => {
    return meterStore().getMeter(options.meterId);
  };

  const getConsumption = async (options: { meterId: number; start: string; end: string; aggregationLevel: string }) => {
    const data = await getConsumptionByMeter({
      meterId: options.meterId,
      start: options.start,
      end: options.end,
      aggregationLevel: options.aggregationLevel,
    });
    return data;
  };

  const getAlert = (options: { meterId: string; type: string }) => {
    return alertStore().getAlertByMeterIdAndType(parseInt(options.meterId), options.type);
  };

  const formatConsumptionForGraph = (consumptions: any) => {
    if (!consumptions) return { consumption_kwh: [], timestamps: [], cost: [] };
    return {
      consumption_kwh: consumptions.map((item: any) => item.consumption_kwh),
      power_kw: consumptions.map((item: any) => item.power_kw),
      timestamps: consumptions.map((item: any) => (item.date ? item.date : item.timestamp)),
      cost: consumptions.map((item: any) => item.cost),
    };
  };

  const getAlertTypeFromAggregationLevel = (aggregationLevel: string) => {
    switch (aggregationLevel) {
      case 'hour':
        return 'hourly_consumption';
      case 'day':
        return 'daily_consumption';
      case 'week':
        return 'weekly_consumption';
      case 'month':
        return 'monthly_consumption';
      default:
        return 'none';
    }
  };

  return {
    getMeter,
    getConsumption,
    formatConsumptionForGraph,
    getAlert,
    getAlertTypeFromAggregationLevel,
  };
};
