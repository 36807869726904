<script setup lang="ts">
import type { ChartOptions } from 'chart.js';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

// Composables
const { t } = useI18n();

const props = defineProps<{
  consumption: {
    timestamps: string[];
    consumption_kWh: number[];
    power_kW: number[];
  };
}>();

const startDate = dayjs(props.consumption.timestamps[0]).format('YYYY-MM-DD HH:mm:ss');
const endDate = dayjs(props.consumption.timestamps[props.consumption.timestamps.length - 1]).format('YYYY-MM-DD HH:mm:ss');

const isSuperiorTo24Hours = dayjs(endDate).diff(dayjs(startDate), 'hours') > 24;
const isSuperiorToaWeek = dayjs(endDate).diff(dayjs(startDate), 'days') >= 6;
const isSuperiorTo2Weeks = dayjs(endDate).diff(dayjs(startDate), 'days') > 14;
const isSuperiorToaMonth = dayjs(endDate).diff(dayjs(startDate), 'days') > 27;

const options: ChartOptions = {
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: t('analytics.power_in_kw'),
        padding: 8,
      },
      grace: '5',
    },
    x: {
      title: {
        display: true,
        text: `${t('global.time')}`,
        padding: 8,
      },
      ticks: {
        autoSkip: false,
        callback: function (val) {
          const hours = dayjs(this.getLabelForValue(val).toString()).locale('fr').format('HH:mm');
          if (isSuperiorToaMonth) {
            const validHours = ['00:00'];
            return validHours.includes(hours) ? dayjs(this.getLabelForValue(val)).locale('fr').format('ddd DD HH:mm') : '';
          } else if (isSuperiorTo2Weeks) {
            const validHours = ['00:00', '12:00'];
            return validHours.includes(hours) ? dayjs(this.getLabelForValue(val)).locale('fr').format('ddd DD HH:mm') : '';
          } else if (isSuperiorToaWeek) {
            const validHours = ['00:00', '06:00', '12:00', '18:00'];
            return validHours.includes(hours) ? dayjs(this.getLabelForValue(val)).locale('fr').format('ddd HH:mm') : '';
          } else if (isSuperiorTo24Hours) {
            const validHours = ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00'];
            return validHours.includes(hours) ? dayjs(this.getLabelForValue(val)).locale('fr').format('ddd HH:mm') : '';
          } else {
            // return all hours endwith 00 or 30
            return hours.endsWith('00') || hours.endsWith('30') ? dayjs(this.getLabelForValue(val)).locale('fr').format('HH:mm') : '';
          }
        },
      },
      grid: {
        display: false,
      },
    },
  },
  elements: {
    point: {
      pointStyle: false,
    },
    line: {
      borderWidth: 8,
    },
  },
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  plugins: {
    tooltip: {
      position: 'average',
      rtl: true,
      callbacks: {
        label: (context: any) => {
          const label = context.dataset.label || '';
          const value = context.parsed.y || 0;
          return `${label}: ${Math.round(value)} kW`;
        },
        title: (context: any) => {
          return dayjs(context[0].label)
            .locale('fr')
            .format(`dddd D MMMM YYYY ${t('global.at')} HH:mm`);
        },
      },
    },
  },
};
</script>
<template>
  <ChartWrapper
    type="line"
    :data="{
      labels: props.consumption.timestamps,
      datasets: [
        {
          label: $t('global.power'),
          data: props.consumption.power_kW,
          borderWidth: 1,
          borderColor: '#FA541C',
          backgroundColor: '#FFF2E8',
        },
      ],
    }"
    :options="options"
    :plugins="{ 'chartjs-plugin-zoom': true, legend: false }"
  />
</template>
