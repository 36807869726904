import { defineStore } from 'pinia';
import { FABRICATION_ORDERS_QUERY_LIST_BY_AREA } from '~/graphql/fabrication_orders';
import dayjs from 'dayjs';
import type { FabricationOrdersQueryListQuery } from '~/types/graphql/graphql';

interface State {
  search: string;
  loading: boolean;
  fabricationOrders: FabricationOrdersQueryListQuery['fabrication_orders'];
}

export const epiServices = defineStore({
  id: 'epiServices',
  state: (): State => ({
    search: '',
    loading: false,
    fabricationOrders: [],
  }),

  getters: {
    graphFormatEPIResponse(): { timestamps: string[]; epis: number[] } {
      return {
        timestamps: this.fabricationOrdersFiltered.map((fabricationOrder) => fabricationOrder.start),
        epis: this.fabricationOrdersFiltered.map((fabricationOrder) => fabricationOrder.fabrication_order_epis[0]?.epi ?? 0),
      };
    },
    fabricationOrdersFiltered(): FabricationOrdersQueryListQuery['fabrication_orders'] {
      return this.fabricationOrders.filter((fabricationOrder) => {
        if (this.search === '') {
          return true;
        } else {
          if (fabricationOrder.fabrication_order_products[0]?.product?.client_product_id === this.search) {
            return true;
          }
          return false;
        }
      });
    },
  },
  actions: {
    async setSearch(search: string) {
      this.search = search;
    },
    async fetchFabricationOrders() {
      this.loading = true;

      const { apolloClient } = useApollo();

      const meterFromStore = appStore().getMeter;
      if (!meterFromStore) return;
      const area = areaStore().getAreaByMeterId(meterFromStore);

      if (!area) {
        this.loading = false;
        return;
      }

      const result = await apolloClient.query<FabricationOrdersQueryListQuery>({
        query: FABRICATION_ORDERS_QUERY_LIST_BY_AREA,
        variables: {
          area_id: area.id,
          meter_id: meterFromStore,
          start: dayjs(appStore().getExploreDateRange.start).format('YYYY-MM-DD'),
          end: dayjs(appStore().getExploreDateRange.end).format('YYYY-MM-DD'),
        },
      });

      this.fabricationOrders = result.data.fabrication_orders;

      this.loading = false;
    },
  },
});
