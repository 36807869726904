import { useConsumption } from './useConsumption';
import type { Consumption } from '~/types/API/consumption';

interface Data {
  id: number;
  name: string;
  consumption: Consumption[];
}

export const useExploreWater = (options: { dateRange: { start: string; end: string } }) => {
  // Composables
  const { getConsumption } = useConsumption();
  const dateRange = ref<{ start: string; end: string }>(options.dateRange);

  // Variables
  const backgroundColors = ['#172554', '#1e3a8a', '#1e40af', '#1d4ed8', '#2563eb', '#3b82f6', '#60a5fa', '#93c5fd', '#bfdbfe'];
  const loading = ref<boolean>(false);
  const groupChild = ref<string>('meter');
  const aggregationLevel = ref<string>('day');
  const data = ref<Data[]>([]);

  // Computed
  const meterId = computed(() => appStore().getMeter);
  const currentAreaId = computed(() => meterStore().getMeter(meterId.value ?? 0)?.area.id);
  const currentArea = computed(() => (currentAreaId.value ? areaStore().getArea(currentAreaId.value) : undefined));
  const currentMeter = computed(() => meterStore().getMeter(meterId.value ?? 0));
  const timestamps = computed(() => data.value?.[0]?.consumption.map((consumption) => consumption.date) ?? []);

  const childMeters = computed(() =>
    currentArea
      ? currentArea.value?.children
          .map((childArea) => {
            const meters = areaStore()
              .getArea(childArea.id)
              ?.meters.filter((meter) => meter.meter_type.energy_type === 'Water');
            if (!meters) return [];

            return meters.map((meter) => {
              return {
                id: meter.id,
                name: childArea.name,
              };
            });
          })
          .flat()
      : undefined,
  );

  const datasets = computed(() => {
    const newDataSets = data.value?.map((element, index) => ({
      data: element.consumption.map((consumption) => consumption.consumption_kwh),
      label: element.name,
      backgroundColor: data.value.length > 1 ? backgroundColors[index % backgroundColors.length] : '#1e40af',
      borderRadius: 2,
      hoverBackgroundColor: '#2563eb',
      minBarLength: 5,
    }));
    return newDataSets ?? [{ data: [], label: '', backgroundColor: '' }];
  });

  // Methods
  async function getConsumptions() {
    loading.value = true;
    if ((childMeters.value?.length === 0 || groupChild.value === 'meter') && currentMeter.value) {
      groupChild.value = 'meter';
      data.value = [
        {
          id: currentMeter.value.id,
          name: currentMeter.value.area.name,
          consumption: await getConsumption({
            meterId: currentMeter.value?.id ?? 0,
            ...dateRange.value,
            aggregationLevel: aggregationLevel.value,
          }),
        },
      ];
    } else if (childMeters.value) {
      data.value = await Promise.all(
        childMeters.value.map(async (meter) => {
          return {
            ...meter,
            consumption: await getConsumption({
              meterId: meter.id,
              ...dateRange.value,
              aggregationLevel: aggregationLevel.value,
            }),
          };
        }),
      );
    } else {
      // Show error
      return [];
    }
    loading.value = false;
  }
  function reload(newDateRange: { start: string; end: string }) {
    dateRange.value = newDateRange;
    getConsumptions();
  }

  // Lifecycle
  onMounted(async () => {
    const meter = meterStore().getMetersByType('Water')[0];
    await appStore().setMeter(meter.id);
    getConsumptions();
  });
  watch(
    () => meterId.value,
    () => {
      getConsumptions();
    },
  );

  return {
    timestamps,
    childMeters,
    data,
    datasets,
    aggregationLevel,
    groupChild,
    loading,
    reload,
  };
};
